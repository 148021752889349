<template>
    <div>
                    <datatable  :territories="territories" :territory_ids="territory_ids" :isBilled="1" 
                    :customerCategory="customerCategory_ids" :salesPersonal="salesPersonal_ids" 
                    :filterDate="filterDate" :filterPostingDate="filterPostingDate" :filterBillingDate="filterBillingDate" :draw="draw" :supplier="supplier_ids" :soDate="soDate"></datatable>
                
    </div>
</template>


<script>
import datatable from "./datatable.vue";

export default {
    components: {
        datatable
    },
    props: 
        ["operatingUnit", "territories", "customerCategory", "salesPersonal", "filterDate", "filterPostingDate", "filterBillingDate", "draw", "supplier", "soDate"],
        data() {
            return {
                // 
                territory_ids: "",
                customerCategory_ids: "",
                supplier_ids: "",
                salesPersonal_ids: ""
            };
        },
    watch: {
        // 
        watchedProperties: function () {      
            this.setIDTerr(); 
            this.setCustomerCategory();
            this.setSalesPersonal();
            this.setSupplier();
        }
    },
    mounted() {
        //
        this.setIDTerr(); 
        this.setCustomerCategory();
        this.setSalesPersonal();
        this.setSupplier();
    },
    computed: {
        // 
        watchedProperties() {
            return `${this.draw}`;
        } 
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        setIDTerr() {
            let datas = this.territories
            let keys = Object.keys(datas)
            let count = datas.length
            let dataIDS = ""

            keys.forEach(function (i) {
                if (datas[i].ID != 0) {
                    dataIDS += datas[i].ID
                    if ((parseInt(i) + 1) != count) {
                        dataIDS += ","
                    }
                }
            });
            this.territory_ids = dataIDS
        },
        setCustomerCategory() {
            let datas = this.customerCategory
            let keys = Object.keys(datas)
            let count = datas.length
            let dataIDS = ""

            keys.forEach(function (i) {
                    dataIDS += datas[i].ID
                    if ((parseInt(i) + 1) != count) {
                        dataIDS += ","
                    }
            });
            if (dataIDS == "0") {
                dataIDS = ""
            }
            this.customerCategory_ids = dataIDS
        },
        setSalesPersonal() {
            let datas = this.salesPersonal
            let keys = Object.keys(datas)
            let count = datas.length
            let dataIDS = ""

            keys.forEach(function (i) {
                    dataIDS += datas[i].id
                    if ((parseInt(i) + 1) != count) {
                        dataIDS += ","
                    }
            });
            if (dataIDS == "0") {
                dataIDS = ""
            }
            this.salesPersonal_ids = dataIDS
        },
        setSupplier() {
            let datas = this.supplier
            let keys = Object.keys(datas)
            let count = datas.length
            let dataIDS = ""

            keys.forEach(function (i) {
                    dataIDS += datas[i].ID
                    if ((parseInt(i) + 1) != count) {
                        dataIDS += ","
                    }
            });
            if (dataIDS == "0") {
                dataIDS = ""
            }
            this.supplier_ids = dataIDS
        },
    }
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>